var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('loading',{attrs:{"active":_vm.isLoading,"is-full-page":false},on:{"update:active":function($event){_vm.isLoading=$event}}}),_c('form',{staticClass:"px-6",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submitStep)}}},[_c('div',{staticClass:"flex items-center mb-3 font-bold text-gray-500 mt-5"},[_c('span',[_vm._v(_vm._s(_vm.$t( 'components.rentPricingPlanManagement.addEdit.steps.extras.title.addonsPlan' )))])]),_c('section',[_c('div',{staticClass:"flex items-center my-5 text-sm font-bold text-gray-500 "},[_c('span',[_vm._v(_vm._s(_vm.$t( 'components.rentPricingPlanManagement.addEdit.steps.extras.title.addons' )))]),_c('div',{staticClass:"col-span-1 ml-2 cursor-pointer focus:text-gray-400",on:{"click":_vm.onAddRentalAddons}},[_c('i',{staticClass:"fas fa-plus-circle",staticStyle:{"color":"black"}})])]),_vm._l((_vm.rentAddons),function(rentAddon,rentAddonIndex){return _c('div',{key:rentAddonIndex},[_c('div',{staticClass:"flex items-center"},[_c('div',{staticClass:"flex items-start  gap-x-1 w-11/12  "},[_c('div',{staticClass:"w-6/12   "},[_c('AppInput',{attrs:{"type":"richselect","name":_vm.$t(
                    'components.rentPricingPlanManagement.addEdit.steps.extras.title.addonsType'
                  ),"label":_vm.$t(
                    'components.rentPricingPlanManagement.addEdit.steps.extras.title.addonsType'
                  ),"placeholder":_vm.$t(
                    'components.rentPricingPlanManagement.addEdit.steps.extras.placeHolder.addonsType'
                  ),"value-attribute":"value","text-attribute":"text","options":_vm.rentAddonsChoices,"hide-search-box":""},model:{value:(rentAddon.type),callback:function ($$v) {_vm.$set(rentAddon, "type", $$v)},expression:"rentAddon.type"}})],1),_c('div',{staticClass:"w-6/12"},[_c('AppInput',{attrs:{"type":"text","rules":"required","name":_vm.$t(
                    'components.rentPricingPlanManagement.addEdit.steps.extras.title.addonsCost'
                  ),"label":_vm.$t(
                    'components.rentPricingPlanManagement.addEdit.steps.extras.title.addonsCost'
                  ),"placeholder":"e.g. 5","haveUnitText":true,"unitText":_vm.selectedFleetCurrency},model:{value:(rentAddon.per_unit_fee),callback:function ($$v) {_vm.$set(rentAddon, "per_unit_fee", _vm._n($$v))},expression:"rentAddon.per_unit_fee"}})],1)]),_c('div',{staticClass:"flex  w-1/12 item-center justify-center"},[_c('div',{staticClass:"col-span-1 cursor-pointer focus:text-gray-400",on:{"click":function($event){return _vm.onRemoveRentalAddons(rentAddonIndex)}}},[_c('i',{staticClass:"fas fa-minus-circle",staticStyle:{"color":"#d90a20"}})])])])])})],2),_c('button',{ref:"submitButton",staticClass:"hidden",attrs:{"type":"submit"}},[_vm._v(" Save ")])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }