export const RentPlanConfig = {
  api: {
    // get
    index: '/dashboard/rental-pricing-plans/',
    // post
    create: '/dashboard/rental-pricing-plans/',
    // patch
    update: (id = 'uuid') => {
      return `/dashboard/rental-pricing-plans/${id}/`
    },

    //get
    details: (id = 'uuid') => {
      return `/dashboard/rental-pricing-plans/${id}/`
    },
    // get
    vehicleRentalFeeUnitChoices: '/dashboard/rental-fee-unit-choices/',
    //get
    vehicleModelChoices: (fleet_id = 'uuid') => {
      return `/dashboard/available-org-vehicle-model-choices/${fleet_id}/`
    },
  },
  events: {
    name: 'rent-pricing-plan',
    // refresh-index-data
    refresh: `rid-rent-pricing-plan`,
    // slideover-right
    sorId: 'rent-pricing-plan',
    sorOpen: 'sor-open-rent-pricing-plan',
    sorClose: 'sor-close-rent-pricing-plan',
    sorToggle: 'sor-toggle-rent-pricing-plan',
    // editing-data
    editingData: 'edit-rent-pricing-plan-data',
    // viewing-data
    viewingData: 'view-rent-pricing-plan-data',
  },
}
