<template>
  <ValidationObserver ref="observerRef" v-slot="{ handleSubmit }">
    <loading :active.sync="isLoading" :is-full-page="false" />

    <form @submit.prevent="handleSubmit(submitStep)" class="px-6">
      <div class="flex items-start">
        <div class="w-1/2">
          <AppInput
            v-model="form.name"
            type="text"
            rules="required"
            :name="
              $t(
                'components.rentPricingPlanManagement.addEdit.steps.essential.title.pricingName'
              )
            "
            :label="
              $t(
                'components.rentPricingPlanManagement.addEdit.steps.essential.title.pricingName'
              )
            "
            :infoDescription="
              $t(
                'components.rentPricingPlanManagement.addEdit.steps.essential.infoDescription.pricingName'
              )
            "
          />
        </div>

        <div class="w-1/2 pl-2">
          <AppInput
            v-model="form.is_active"
            type="richselect"
            rules="required"
            :name="
              $t(
                'components.rentPricingPlanManagement.addEdit.steps.essential.title.status'
              )
            "
            :label="
              $t(
                'components.rentPricingPlanManagement.addEdit.steps.essential.title.status'
              )
            "
            :placeholder="
              $t(
                'components.rentPricingPlanManagement.addEdit.steps.essential.placeHolder.status'
              )
            "
            value-attribute="value"
            text-attribute="text"
            :options="[
              { text: 'Active', value: true },
              { text: 'Inactive', value: false },
            ]"
            hide-search-box
          />
        </div>
      </div>

      <div class="flex items-start">
        <div class="w-1/2 ">
          <AppInput
            v-model="form.fleet"
            type="richselect"
            rules="required"
            :name="
              $t(
                'components.rentPricingPlanManagement.addEdit.steps.essential.title.fleet'
              )
            "
            :label="
              $t(
                'components.rentPricingPlanManagement.addEdit.steps.essential.title.fleet'
              )
            "
            :infoDescription="
              $t(
                'components.rentPricingPlanManagement.addEdit.steps.essential.infoDescription.fleet'
              )
            "
            :placeholder="
              $t(
                'components.rentPricingPlanManagement.addEdit.steps.essential.placeHolder.fleet'
              )
            "
            value-attribute="id"
            text-attribute="name"
            :options="fleets"
            hide-search-box
          />
        </div>

        <div class="w-1/2 pl-2">
          <AppInput
            rules=""
            :name="
              $t(
                'components.rentPricingPlanManagement.addEdit.steps.essential.title.fleetCurrency'
              )
            "
            :label="
              $t(
                'components.rentPricingPlanManagement.addEdit.steps.essential.title.fleetCurrency'
              )
            "
            :isInfoEnabled="false"
            :value="
              selectedFleetCurrency ||
                $t(
                  'components.rentPricingPlanManagement.addEdit.steps.essential.placeHolder.fleetCurrency'
                )
            "
            disabled
          />
        </div>
      </div>
      <div class="w-full">
        <AppInput
          v-model="form.vehicle_category"
          type="richselect"
          :name="
            $t(
              'components.rentPricingPlanManagement.addEdit.steps.essential.title.vehicleCategory'
            )
          "
          :label="
            $t(
              'components.rentPricingPlanManagement.addEdit.steps.essential.title.vehicleCategory'
            )
          "
          rules="required"
          text-attribute="text"
          value-attribute="value"
          :options="vehicleCategories"
          hide-search-box
        />
      </div>
      <div class="w-full">
        <AppInput
          multiple
          type="richselect"
          :name="
            $t(
              'components.rentPricingPlanManagement.addEdit.steps.essential.title.vehicleModel'
            )
          "
          :label="
            $t(
              'components.rentPricingPlanManagement.addEdit.steps.essential.title.vehicleModel'
            )
          "
          :placeholder="getVehicleModelsPlaceholder"
          rules="required"
          :options.sync="modifiedVehicleModels"
          valueAttribute="id"
          textAttribute="title"
          :hide-search-box="true"
          v-model="form.organization_vehicle_models"
          :disabled="getVehicleModelsDisabledState"
        />
      </div>

      <div class="flex items-start py-4">
        <ValidationProvider
          vid="tax_enabled"
          name=" "
          rules=""
          v-slot="{ errors }"
        >
          <input-label
            :error="errors[0]"
            :text="
              $t(
                'components.rentPricingPlanManagement.addEdit.steps.essential.title.taxPlan'
              )
            "
          />
          <div class="flex">
            <label class="flex items-center mt-px ml-2 cursor-pointer">
              <input
                v-model="form.tax_enabled"
                type="radio"
                class="p-1 form-radio"
                name="useTaxPlanField"
                :value="true"
              />
              <span class="ml-2 text-sm">{{
                $t(
                  'components.rentPricingPlanManagement.addEdit.steps.essential.title.yes'
                )
              }}</span>
            </label>

            <label class="flex items-center mt-px ml-4 cursor-pointer">
              <input
                v-model="form.tax_enabled"
                type="radio"
                class="p-1 form-radio"
                name="useTaxPlanField"
                :value="false"
              />
              <span class="ml-2 text-sm">{{
                $t(
                  'components.rentPricingPlanManagement.addEdit.steps.essential.title.no'
                )
              }}</span>
            </label>
          </div>
        </ValidationProvider>
      </div>

      <div class="flex items-start mt-5" v-if="form.tax_enabled">
        <div class="w-full ">
          <AppInput
            v-model="form.tax_plan"
            type="richselect"
            :rules="form.tax_enabled ? 'required' : ''"
            :name="
              $t(
                'components.rentPricingPlanManagement.addEdit.steps.essential.title.chooseTaxPlan'
              )
            "
            :label="
              $t(
                'components.rentPricingPlanManagement.addEdit.steps.essential.title.chooseTaxPlan'
              )
            "
            :infoDescription="
              $t(
                'components.rentPricingPlanManagement.addEdit.steps.essential.infoDescription.chooseTaxPlan'
              )
            "
            :placeholder="getTaxPlanPlaceholder"
            value-attribute="value"
            text-attribute="text"
            :disabled="getTaxPlanDisabledState"
            :options="modifiedTaxPlan"
            hide-search-box
          />
          <p
            v-if="!isTaxPlanLoading && taxPlans.length === 0"
            class="text-sm text-gray-500"
          >
            <RouterLink
              :to="{
                name: 'ViewTaxPlan',
              }"
              target="_blank"
              class="text-blue-600 capitalize font-semibold"
            >
              {{
                $t(
                  'components.rentPricingPlanManagement.addEdit.steps.essential.placeHolder.chooseTaxPlan.createTaxPlan'
                )
              }}
            </RouterLink>
            {{
              $t(
                'components.rentPricingPlanManagement.addEdit.steps.essential.placeHolder.chooseTaxPlan.atFirst'
              )
            }}
          </p>
        </div>
      </div>
      <div class="flex items-start">
        <div class="w-1/2 ">
          <AppInput
            type="number"
            rules="required"
            :name="
              $t(
                'components.rentPricingPlanManagement.addEdit.steps.essential.title.rentPerUnitFee'
              )
            "
            :label="
              $t(
                'components.rentPricingPlanManagement.addEdit.steps.essential.title.rentPerUnitFee'
              )
            "
            v-model="form.vehicle_rental_per_unit_fees"
            :step="0.01"
            :haveSuggestion="form.tax_enabled"
            :suggestionText="
              getVatIncludedAmount(form.vehicle_rental_per_unit_fees)
            "
            :haveUnitText="true"
            :unitText="selectedFleetCurrency"
          />
        </div>

        <div class="w-1/2 pl-2">
          <AppInput
            v-model="form.vehicle_rental_fee_unit"
            type="richselect"
            rules="required"
            :name="
              $t(
                'components.rentPricingPlanManagement.addEdit.steps.essential.title.rentFeeUnit'
              )
            "
            :label="
              $t(
                'components.rentPricingPlanManagement.addEdit.steps.essential.title.rentFeeUnit'
              )
            "
            :placeholder="
              $t(
                'components.rentPricingPlanManagement.addEdit.steps.essential.placeHolder.rentFeeUnit'
              )
            "
            value-attribute="value"
            text-attribute="text"
            :options="rentalFeeUnitChoices"
            hide-search-box
          />
        </div>
      </div>
      <div class="w-full ">
        <AppInput
          type="number"
          rules="required"
          :name="
            $t(
              'components.rentPricingPlanManagement.addEdit.steps.essential.title.minRentalFee'
            )
          "
          :label="
            $t(
              'components.rentPricingPlanManagement.addEdit.steps.essential.title.minRentalFee'
            )
          "
          :step="0.01"
          v-model="form.minimum_rental_fee"
          :haveSuggestion="form.tax_enabled"
          :suggestionText="getVatIncludedAmount(form.minimum_rental_fee)"
          :haveUnitText="true"
          :unitText="selectedFleetCurrency"
        />
      </div>

      <!-- rent-packages -->
      <div class="flex items-center mb-3 font-bold text-gray-500 mt-5">
        <span>{{
          $t(
            'components.rentPricingPlanManagement.addEdit.steps.essential.title.rentPackages'
          )
        }}</span>
      </div>
      <section
        v-for="(rentalPackage, rentalPackageIndex) in rentalPackages"
        :key="rentalPackageIndex"
      >
        <div class="flex items-center my-5 text-sm font-bold text-gray-500 ">
          <span>{{ getRentalPackageTitle(rentalPackage.value) }}</span>
          <div
            class="col-span-1 ml-2 cursor-pointer focus:text-gray-400"
            @click="onAddRentalPackage(rentalPackageIndex, rentalPackage.value)"
          >
            <i class="fas fa-plus-circle" style="color: black"></i>
          </div>
        </div>

        <div
          v-for="(packageData, packageDataIndex) in rentalPackage.data"
          :key="packageDataIndex"
        >
          <div class="flex items-center">
            <div class="flex items-start  gap-x-1 w-11/12  ">
              <div class="w-6/12   ">
                <AppInput
                  v-model="packageData.period"
                  type="richselect"
                  :name="
                    $t(
                      'components.rentPricingPlanManagement.addEdit.steps.essential.title.rentalType'
                    )
                  "
                  :label="
                    $t(
                      'components.rentPricingPlanManagement.addEdit.steps.essential.title.rentalType'
                    )
                  "
                  :infoDescription="
                    $t(
                      'components.rentPricingPlanManagement.addEdit.steps.essential.infoDescription.rentalType'
                    )
                  "
                  :placeholder="
                    $t(
                      'components.rentPricingPlanManagement.addEdit.steps.essential.placeHolder.rentalType'
                    )
                  "
                  value-attribute="value"
                  text-attribute="display_name"
                  :options="rentalPackageTypeOptions[rentalPackage.value]"
                  hide-search-box
                />
              </div>

              <div class="w-6/12   ">
                <AppInput
                  v-model.number="packageData.cost"
                  type="text"
                  rules="required"
                  :name="
                    $t(
                      'components.rentPricingPlanManagement.addEdit.steps.essential.title.rentalCost'
                    )
                  "
                  :label="
                    $t(
                      'components.rentPricingPlanManagement.addEdit.steps.essential.title.rentalCost'
                    )
                  "
                  :infoDescription="
                    $t(
                      'components.rentPricingPlanManagement.addEdit.steps.essential.infoDescription.rentalCost'
                    )
                  "
                  placeholder="e.g. 5"
                  :haveSuggestion="parseFloat(packageData.cost) ? true : false"
                  :isSuggestionAlert="
                    getSavePercentage(
                      parseFloat(packageData.period),
                      parseFloat(packageData.cost),
                      rentalPackage.value
                    ) < 0
                      ? true
                      : false
                  "
                  :suggestionText="
                    `${
                      form.tax_enabled
                        ? getVatIncludedAmount(packageData.cost)
                        : ''
                    }, Save ${getSavePercentage(
                      parseFloat(packageData.period),
                      parseFloat(packageData.cost),
                      rentalPackage.value
                    )}%`
                  "
                  :haveUnitText="true"
                  :unitText="selectedFleetCurrency"
                />
              </div>
            </div>

            <div class="flex  w-1/12 item-center justify-center">
              <div
                class="col-span-1 cursor-pointer focus:text-gray-400"
                @click="
                  onRemoveRentPackage(rentalPackageIndex, packageDataIndex)
                "
              >
                <i class="fas fa-minus-circle" style="color: #d90a20"></i>
              </div>
            </div>
          </div>
          <div class="pb-2">
            <section>
              <t-checkbox
                wrapped
                v-model="packageData.addDescriptionUI"
                :name="
                  $t(
                    'components.rentPricingPlanManagement.addEdit.steps.essential.title.addNoteCheckbox'
                  )
                "
                :label="
                  $t(
                    'components.rentPricingPlanManagement.addEdit.steps.essential.title.addNoteCheckbox'
                  )
                "
              />
            </section>

            <section v-show="packageData.addDescriptionUI">
              <AppInput
                v-model="packageData.description"
                rules=""
                :name="
                  $t(
                    'components.rentPricingPlanManagement.addEdit.steps.essential.title.notes'
                  )
                "
                :label="
                  $t(
                    'components.rentPricingPlanManagement.addEdit.steps.essential.title.notes'
                  )
                "
                type="textarea"
              />
            </section>
          </div>
        </div>
      </section>

      <div class="w-full mt-5">
        <AppInput
          v-model="form.description"
          type="textarea"
          :name="
            $t(
              'components.rentPricingPlanManagement.addEdit.steps.essential.title.billingDescription'
            )
          "
          :label="
            $t(
              'components.rentPricingPlanManagement.addEdit.steps.essential.title.billingDescription'
            )
          "
          :infoDescription="
            $t(
              'components.rentPricingPlanManagement.addEdit.steps.essential.infoDescription.billingDescription'
            )
          "
        />
      </div>

      <button type="submit" ref="submitButton" class="hidden">
        Save
      </button>
    </form>
  </ValidationObserver>
</template>

<script>
import { deepCompareObjects } from '@/utils'
import InputLabel from '@/components/form/InputLabel'
import { RentPlanConfig } from '@/config/RentPlanConfig'
import {
  generateRentDataModel,
  choiceMappingForPackages,
} from '@/views/pricing/add-edit-rent-plan/helper.js'
import { useEndpoints } from '@/composables'
export default {
  name: 'Step1',

  components: {
    InputLabel,
  },

  props: {
    primaryKey: {
      type: null,
      required: false,
    },
    formData: {
      required: false,
    },
    isEditing: {
      type: Boolean,
      required: false,
      default: false,
    },
    isTaxPlanLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    rentTypeChoices: {
      type: Array,
      default: () => [],
    },
    vehicleCategories: {
      type: Array,
      default: () => [],
    },
    vehicleModels: {
      type: Array,
      default: () => [],
    },
    rentalFeeUnitChoices: {
      type: Array,
      default: () => [],
    },
    fleets: {
      type: Array,
      default: () => [],
    },
    taxPlans: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      isLoading: false,
      isVehicleModelLoading: false,
      taxPercentage: 0,
      modifiedTaxPlan: [],
      modifiedVehicleModels: [],
      form: {},
      rentalPackages: [],
      rentalPackageTypeOptions: {
        //hourly
        H: [
          {
            display_name: '1 hour',
            value: 1,
          },
          {
            display_name: '2 hours',
            value: 2,
          },
          {
            display_name: '4 hours',
            value: 4,
          },
          {
            display_name: '8 hours',
            value: 8,
          },
          {
            display_name: '16 hours',
            value: 16,
          },
          {
            display_name: '24 hours',
            value: 24,
          },
        ],
        //daily
        D: [
          {
            display_name: '2 days',
            value: 2,
          },
          {
            display_name: '3 days',
            value: 3,
          },
          {
            display_name: '5 days',
            value: 5,
          },
          {
            display_name: '7 days',
            value: 7,
          },
          {
            display_name: '15 days',
            value: 15,
          },
        ],
        //monthly
        M: [
          {
            display_name: '1 month',
            value: 1,
          },
          {
            display_name: '3 month',
            value: 3,
          },
          {
            display_name: '6 month',
            value: 6,
          },
          {
            display_name: '12 month',
            value: 12,
          },
        ],
      },
      rentalPackageChoices: choiceMappingForPackages,
      deleteRentPackages: [],
    }
  },

  computed: {
    selectedFleet() {
      return this.form.fleet
        ? this.fleets.find((fleet) => fleet.id == this.form.fleet)
        : null
    },
    selectedFleetCurrency() {
      return this.selectedFleet
        ? this.selectedFleet?.country?.currency_symbol
        : '--'
    },
    selectedFleetCurrencyFormatted() {
      return this.selectedFleetCurrency ? `(${this.selectedFleetCurrency})` : ''
    },
    getVehicleModelsPlaceholder() {
      if (!this.form.fleet) {
        return this.$t(
          'components.rentPricingPlanManagement.addEdit.steps.essential.placeHolder.vehicleModel.atFirst'
        )
      } else {
        if (this.isVehicleModelLoading) {
          return this.$t(
            'components.rentPricingPlanManagement.addEdit.steps.essential.placeHolder.vehicleModel.loading'
          )
        } else {
          if (!this.isVehicleModelLoading && this.modifiedVehicleModels) {
            return this.$t(
              'components.rentPricingPlanManagement.addEdit.steps.essential.placeHolder.vehicleModel.selectVehicleModel'
            )
          } else return ''
        }
      }
    },
    getVehicleModelsDisabledState() {
      if (!this.form.fleet) {
        return true
      } else {
        if (this.isVehicleModelLoading) {
          return true
        } else {
          if (!this.isVehicleModelLoading && this.modifiedVehicleModels) {
            return false
          } else return true
        }
      }
    },
    getTaxPlanPlaceholder() {
      if (this.isTaxPlanLoading)
        return this.$t(
          'components.rentPricingPlanManagement.addEdit.steps.essential.placeHolder.chooseTaxPlan.loading'
        )
      if (this.isTaxPlanLoading === false && this.taxPlans.length === 0)
        return this.$t(
          'components.rentPricingPlanManagement.addEdit.steps.essential.placeHolder.chooseTaxPlan.noTaxPlan'
        )
      return this.$t(
        'components.rentPricingPlanManagement.addEdit.steps.essential.placeHolder.chooseTaxPlan.selectTaxPlan'
      )
    },
    getTaxPlanDisabledState() {
      if (this.isTaxPlanLoading) return true
      if (this.isTaxPlanLoading === false && this.taxPlans.length === 0)
        return true
      return false
    },
  },

  watch: {
    // sync props.formData with $data.from
    'formData': {
      deep: false,
      immediate: true,
      handler(data) {
        if (data) {
          console.log('formData-b', data)

          this.form = {
            ...data.rentPlanData,
            fleet: data.rentPlanData.fleet.id || null,
            tax_plan: data.rentPlanData.tax_plan
              ? data.rentPlanData.tax_plan.id
              : null,
          }
          this.rentalPackages = data.rentPackages
        }
      },
    },
    'vehicleModels': {
      deep: false,
      immediate: true,
      handler(data) {
        this.modifiedVehicleModels = data
      },
    },
    'taxPlans': {
      deep: false,
      immediate: true,
      handler(data) {
        this.modifiedTaxPlan = data.map((item) => {
          return {
            text: `${item.name} (${Number(item.percentage)}%)`,
            value: item.id,
          }
        })
      },
    },

    // notify form is dirty & user should confirm before exiting
    'form': {
      deep: true,
      immediate: false,
      handler(updatedFormData) {
        if (deepCompareObjects(this.formData, updatedFormData)) {
          return
        }
        this.$emit('dirty', { step: 1, data: this.form })
      },
    },
    'form.tax_plan': {
      deep: true,
      immediate: true,
      handler(updatedId) {
        if (updatedId && this.taxPlans) {
          this.taxPercentage = Number(
            this.taxPlans.find((item) => item.id === updatedId).percentage
          )
        }
      },
    },
    'form.fleet': {
      deep: true,
      immediate: false,
      handler(fleetId) {
        if (fleetId) {
          this.isVehicleModelLoading = true
          let url = ''
          if (this.isEditing) {
            const isFleetIdChanged =
              this.formData.rentPlanData.fleet.id === fleetId ? false : true

            if (isFleetIdChanged) {
              url = RentPlanConfig.api.vehicleModelChoices(fleetId)
              this.form.organization_vehicle_models = []
            } else {
              url = `${RentPlanConfig.api.vehicleModelChoices(
                fleetId
              )}?to_update_rental_pricing=${this.formData.rentPlanData.id}`

              this.form.organization_vehicle_models = this.formData.rentPlanData.organization_vehicle_models
            }
          } else {
            url = RentPlanConfig.api.vehicleModelChoices(fleetId)
          }

          this.$http
            .get(url)
            .then((res) => {
              this.modifiedVehicleModels = res.data
            })
            .finally(() => (this.isVehicleModelLoading = false))
        }
      },
    },
    'rentTypeChoices': {
      deep: false,
      immediate: true,
      handler(updatedData) {
        if (updatedData.length > 0 && !this.isEditing) {
          this.rentalPackages = this.generateRentDataModel(updatedData)
        }
      },
    },
  },

  methods: {
    generateRentDataModel,
    getVatIncludedAmount(amount) {
      if (amount && this.selectedFleetCurrency) {
        const vatAmount = (Number(this.taxPercentage) * Number(amount)) / 100
        const totalAmount = Number(amount) + vatAmount
        return `${this.selectedFleetCurrency}${Number(
          totalAmount.toFixed(2)
        )} Inc. VAT`
      } else {
        return ''
      }
    },
    getRentalPackageTitle(type) {
      return this.rentalPackageChoices[type]
    },
    onAddRentalPackage(index, typeValue) {
      this.rentalPackages[index].data.unshift({
        period: 0,
        cost: 0,
        type: typeValue,
        addDescriptionUI: false,
        description: '',
      })
    },
    onRemoveRentPackage(parentIndex, childIndex) {
      if (this.isEditing) {
        this.deleteRentPackages.push(
          this.rentalPackages[parentIndex].data[childIndex]
        )
      }
      this.rentalPackages[parentIndex].data.splice(childIndex, 1)
    },

    getSavePercentage(period, packageCost, type) {
      let totalHours = 0
      if (type === 'H') {
        totalHours = period
      }
      if (type === 'D') {
        totalHours = period * 24
      }
      if (type === 'M') {
        totalHours = period * 24 * 30
      }

      if (
        packageCost &&
        this.form.vehicle_rental_per_unit_fees &&
        this.form.vehicle_rental_fee_unit
      ) {
        const packageCostPerHour = packageCost / totalHours
        let rental_unit_fee = 0
        //day calculation
        if (this.form.vehicle_rental_fee_unit === 1) {
          rental_unit_fee = Number(this.form.vehicle_rental_per_unit_fees) / 24
        } else {
          rental_unit_fee = Number(this.form.vehicle_rental_per_unit_fees)
        }

        const savePercentage =
          ((rental_unit_fee - packageCostPerHour) / rental_unit_fee) * 100

        return parseFloat(savePercentage).toFixed(2)
      } else {
        return ''
      }
    },
    async submit() {
      this.$refs.submitButton.click()
    },

    async submitStep() {
      this.isLoading = true

      let method = this.isEditing ? 'PATCH' : 'POST'
      let url = this.isEditing
        ? RentPlanConfig.api.update(this.form.id)
        : RentPlanConfig.api.create

      const formDataProxy = {
        ...this.form,
      }

      let data = new FormData()
      data.append('name', formDataProxy.name)
      data.append('is_active', formDataProxy.is_active)
      data.append('fleet', formDataProxy.fleet)
      data.append('vehicle_category', formDataProxy.vehicle_category)
      data.append('tax_enabled', formDataProxy.tax_enabled)
      data.append(
        'vehicle_rental_per_unit_fees',
        formDataProxy.vehicle_rental_per_unit_fees
      )

      data.append(
        'vehicle_rental_fee_unit',
        formDataProxy.vehicle_rental_fee_unit
      )

      data.append('minimum_rental_fee', formDataProxy.minimum_rental_fee)

      if (formDataProxy?.organization_vehicle_models?.length) {
        formDataProxy.organization_vehicle_models.forEach((id) => {
          data.append('organization_vehicle_models', id)
        })
      }

      if (formDataProxy.tax_enabled) {
        data.append('tax_plan', formDataProxy.tax_plan)
      } else {
        data.append('tax_plan', '')
      }

      await this.$http({ method, url, data })
        .then((res) => {
          console.log(res)
          this.rentPackageSubmit(res.data.id)

          let message = this.isEditing
            ? 'Rent plan updated successfully'
            : 'Rent plan added successfully'
          this.isLoading = false
          this.$emit('save', {
            step: 1,
            data: res.data,
          })

          this.$notify(
            {
              group: 'bottomLeft',
              type: 'success',
              title: 'Success',
              text: message,
            },
            3000
          )
        })
        .catch((err) => {
          console.error('Error occurred!', err.response.data.details)
          // const e = err?.response.data
          // const max = Object.keys(e).length
          // let t = max * 2000 // in ms

          // for (const k in e) {
          //   this.$notify(
          //     {
          //       group: 'bottomLeft',
          //       type: 'error',
          //       title: `Error Occured`,
          //       text: k + ': ' + e[k],
          //     },
          //     t
          //   )
          //   t -= 2000
          // }
          this.$notify({
            group: 'bottomLeft',
            type: 'error',
            title: 'Error occurred!',
            text: err.response.data.details,
          })
        })
        .finally(() => (this.isLoading = false))
    },
    async rentPackageSubmit(rentPlanId) {
      const requests = []
      const rentPackDataReq = []
      this.rentalPackages.forEach((rentPack) => {
        if (rentPack.data.length > 0) {
          rentPack.data.forEach((packData) => {
            const isPackDataUpdateReq = 'id' in packData
            const packDataReqMethod = isPackDataUpdateReq ? 'PATCH' : 'POST'
            const packDataReqUrl = isPackDataUpdateReq
              ? useEndpoints.rentPricingPlan.updateRentPackage(packData.id)
              : useEndpoints.rentPricingPlan.createRentPackage()
            const packDataReqData = new FormData()

            if (isPackDataUpdateReq) {
              packDataReqData.append('cost', packData.cost)
              if (packData.addDescriptionUI) {
                packDataReqData.append('description', packData.description)
              } else {
                packDataReqData.append('description', '')
              }
            } else {
              packDataReqData.append('type', packData.type)
              packDataReqData.append('rental_pricing_plan', rentPlanId)
              packDataReqData.append('period', packData.period)
              packDataReqData.append('cost', packData.cost)
              if (packData.addDescriptionUI) {
                packDataReqData.append('description', packData.description)
              } else {
                packDataReqData.append('description', '')
              }
            }

            const packDataReq = this.$http({
              url: packDataReqUrl,
              method: packDataReqMethod,
              data: packDataReqData,
            })
            rentPackDataReq.push(packDataReq)
          })
        }
      })

      //delete rent packages

      let deleteRentPackageReqs = []
      if (this.isEditing && this.deleteRentPackages.length) {
        this.deleteRentPackages.forEach((element) => {
          if ('id' in element) {
            const deleteRentReq = this.$http({
              url: useEndpoints.rentPricingPlan.deleteRentPackage(element.id),
              method: 'DELETE',
            })
            deleteRentPackageReqs.push(deleteRentReq)
          }
        })
      }
      if (deleteRentPackageReqs.length) {
        requests.push(...deleteRentPackageReqs)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.map-btn {
  cursor: pointer;
  width: 25px;
  height: 25px;
  background: #ff598a;
  color: #fff;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
