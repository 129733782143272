var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observerRef",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('loading',{attrs:{"active":_vm.isLoading,"is-full-page":false},on:{"update:active":function($event){_vm.isLoading=$event}}}),_c('form',{staticClass:"px-6",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submitStep)}}},[_c('div',{staticClass:"flex items-start"},[_c('div',{staticClass:"w-1/2"},[_c('AppInput',{attrs:{"type":"text","rules":"required","name":_vm.$t(
              'components.rentPricingPlanManagement.addEdit.steps.essential.title.pricingName'
            ),"label":_vm.$t(
              'components.rentPricingPlanManagement.addEdit.steps.essential.title.pricingName'
            ),"infoDescription":_vm.$t(
              'components.rentPricingPlanManagement.addEdit.steps.essential.infoDescription.pricingName'
            )},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('div',{staticClass:"w-1/2 pl-2"},[_c('AppInput',{attrs:{"type":"richselect","rules":"required","name":_vm.$t(
              'components.rentPricingPlanManagement.addEdit.steps.essential.title.status'
            ),"label":_vm.$t(
              'components.rentPricingPlanManagement.addEdit.steps.essential.title.status'
            ),"placeholder":_vm.$t(
              'components.rentPricingPlanManagement.addEdit.steps.essential.placeHolder.status'
            ),"value-attribute":"value","text-attribute":"text","options":[
            { text: 'Active', value: true },
            { text: 'Inactive', value: false } ],"hide-search-box":""},model:{value:(_vm.form.is_active),callback:function ($$v) {_vm.$set(_vm.form, "is_active", $$v)},expression:"form.is_active"}})],1)]),_c('div',{staticClass:"flex items-start"},[_c('div',{staticClass:"w-1/2 "},[_c('AppInput',{attrs:{"type":"richselect","rules":"required","name":_vm.$t(
              'components.rentPricingPlanManagement.addEdit.steps.essential.title.fleet'
            ),"label":_vm.$t(
              'components.rentPricingPlanManagement.addEdit.steps.essential.title.fleet'
            ),"infoDescription":_vm.$t(
              'components.rentPricingPlanManagement.addEdit.steps.essential.infoDescription.fleet'
            ),"placeholder":_vm.$t(
              'components.rentPricingPlanManagement.addEdit.steps.essential.placeHolder.fleet'
            ),"value-attribute":"id","text-attribute":"name","options":_vm.fleets,"hide-search-box":""},model:{value:(_vm.form.fleet),callback:function ($$v) {_vm.$set(_vm.form, "fleet", $$v)},expression:"form.fleet"}})],1),_c('div',{staticClass:"w-1/2 pl-2"},[_c('AppInput',{attrs:{"rules":"","name":_vm.$t(
              'components.rentPricingPlanManagement.addEdit.steps.essential.title.fleetCurrency'
            ),"label":_vm.$t(
              'components.rentPricingPlanManagement.addEdit.steps.essential.title.fleetCurrency'
            ),"isInfoEnabled":false,"value":_vm.selectedFleetCurrency ||
              _vm.$t(
                'components.rentPricingPlanManagement.addEdit.steps.essential.placeHolder.fleetCurrency'
              ),"disabled":""}})],1)]),_c('div',{staticClass:"w-full"},[_c('AppInput',{attrs:{"type":"richselect","name":_vm.$t(
            'components.rentPricingPlanManagement.addEdit.steps.essential.title.vehicleCategory'
          ),"label":_vm.$t(
            'components.rentPricingPlanManagement.addEdit.steps.essential.title.vehicleCategory'
          ),"rules":"required","text-attribute":"text","value-attribute":"value","options":_vm.vehicleCategories,"hide-search-box":""},model:{value:(_vm.form.vehicle_category),callback:function ($$v) {_vm.$set(_vm.form, "vehicle_category", $$v)},expression:"form.vehicle_category"}})],1),_c('div',{staticClass:"w-full"},[_c('AppInput',{attrs:{"multiple":"","type":"richselect","name":_vm.$t(
            'components.rentPricingPlanManagement.addEdit.steps.essential.title.vehicleModel'
          ),"label":_vm.$t(
            'components.rentPricingPlanManagement.addEdit.steps.essential.title.vehicleModel'
          ),"placeholder":_vm.getVehicleModelsPlaceholder,"rules":"required","options":_vm.modifiedVehicleModels,"valueAttribute":"id","textAttribute":"title","hide-search-box":true,"disabled":_vm.getVehicleModelsDisabledState},on:{"update:options":function($event){_vm.modifiedVehicleModels=$event}},model:{value:(_vm.form.organization_vehicle_models),callback:function ($$v) {_vm.$set(_vm.form, "organization_vehicle_models", $$v)},expression:"form.organization_vehicle_models"}})],1),_c('div',{staticClass:"flex items-start py-4"},[_c('ValidationProvider',{attrs:{"vid":"tax_enabled","name":" ","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('input-label',{attrs:{"error":errors[0],"text":_vm.$t(
              'components.rentPricingPlanManagement.addEdit.steps.essential.title.taxPlan'
            )}}),_c('div',{staticClass:"flex"},[_c('label',{staticClass:"flex items-center mt-px ml-2 cursor-pointer"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.tax_enabled),expression:"form.tax_enabled"}],staticClass:"p-1 form-radio",attrs:{"type":"radio","name":"useTaxPlanField"},domProps:{"value":true,"checked":_vm._q(_vm.form.tax_enabled,true)},on:{"change":function($event){return _vm.$set(_vm.form, "tax_enabled", true)}}}),_c('span',{staticClass:"ml-2 text-sm"},[_vm._v(_vm._s(_vm.$t( 'components.rentPricingPlanManagement.addEdit.steps.essential.title.yes' )))])]),_c('label',{staticClass:"flex items-center mt-px ml-4 cursor-pointer"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.tax_enabled),expression:"form.tax_enabled"}],staticClass:"p-1 form-radio",attrs:{"type":"radio","name":"useTaxPlanField"},domProps:{"value":false,"checked":_vm._q(_vm.form.tax_enabled,false)},on:{"change":function($event){return _vm.$set(_vm.form, "tax_enabled", false)}}}),_c('span',{staticClass:"ml-2 text-sm"},[_vm._v(_vm._s(_vm.$t( 'components.rentPricingPlanManagement.addEdit.steps.essential.title.no' )))])])])]}}],null,true)})],1),(_vm.form.tax_enabled)?_c('div',{staticClass:"flex items-start mt-5"},[_c('div',{staticClass:"w-full "},[_c('AppInput',{attrs:{"type":"richselect","rules":_vm.form.tax_enabled ? 'required' : '',"name":_vm.$t(
              'components.rentPricingPlanManagement.addEdit.steps.essential.title.chooseTaxPlan'
            ),"label":_vm.$t(
              'components.rentPricingPlanManagement.addEdit.steps.essential.title.chooseTaxPlan'
            ),"infoDescription":_vm.$t(
              'components.rentPricingPlanManagement.addEdit.steps.essential.infoDescription.chooseTaxPlan'
            ),"placeholder":_vm.getTaxPlanPlaceholder,"value-attribute":"value","text-attribute":"text","disabled":_vm.getTaxPlanDisabledState,"options":_vm.modifiedTaxPlan,"hide-search-box":""},model:{value:(_vm.form.tax_plan),callback:function ($$v) {_vm.$set(_vm.form, "tax_plan", $$v)},expression:"form.tax_plan"}}),(!_vm.isTaxPlanLoading && _vm.taxPlans.length === 0)?_c('p',{staticClass:"text-sm text-gray-500"},[_c('RouterLink',{staticClass:"text-blue-600 capitalize font-semibold",attrs:{"to":{
              name: 'ViewTaxPlan',
            },"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t( 'components.rentPricingPlanManagement.addEdit.steps.essential.placeHolder.chooseTaxPlan.createTaxPlan' ))+" ")]),_vm._v(" "+_vm._s(_vm.$t( 'components.rentPricingPlanManagement.addEdit.steps.essential.placeHolder.chooseTaxPlan.atFirst' ))+" ")],1):_vm._e()],1)]):_vm._e(),_c('div',{staticClass:"flex items-start"},[_c('div',{staticClass:"w-1/2 "},[_c('AppInput',{attrs:{"type":"number","rules":"required","name":_vm.$t(
              'components.rentPricingPlanManagement.addEdit.steps.essential.title.rentPerUnitFee'
            ),"label":_vm.$t(
              'components.rentPricingPlanManagement.addEdit.steps.essential.title.rentPerUnitFee'
            ),"step":0.01,"haveSuggestion":_vm.form.tax_enabled,"suggestionText":_vm.getVatIncludedAmount(_vm.form.vehicle_rental_per_unit_fees),"haveUnitText":true,"unitText":_vm.selectedFleetCurrency},model:{value:(_vm.form.vehicle_rental_per_unit_fees),callback:function ($$v) {_vm.$set(_vm.form, "vehicle_rental_per_unit_fees", $$v)},expression:"form.vehicle_rental_per_unit_fees"}})],1),_c('div',{staticClass:"w-1/2 pl-2"},[_c('AppInput',{attrs:{"type":"richselect","rules":"required","name":_vm.$t(
              'components.rentPricingPlanManagement.addEdit.steps.essential.title.rentFeeUnit'
            ),"label":_vm.$t(
              'components.rentPricingPlanManagement.addEdit.steps.essential.title.rentFeeUnit'
            ),"placeholder":_vm.$t(
              'components.rentPricingPlanManagement.addEdit.steps.essential.placeHolder.rentFeeUnit'
            ),"value-attribute":"value","text-attribute":"text","options":_vm.rentalFeeUnitChoices,"hide-search-box":""},model:{value:(_vm.form.vehicle_rental_fee_unit),callback:function ($$v) {_vm.$set(_vm.form, "vehicle_rental_fee_unit", $$v)},expression:"form.vehicle_rental_fee_unit"}})],1)]),_c('div',{staticClass:"w-full "},[_c('AppInput',{attrs:{"type":"number","rules":"required","name":_vm.$t(
            'components.rentPricingPlanManagement.addEdit.steps.essential.title.minRentalFee'
          ),"label":_vm.$t(
            'components.rentPricingPlanManagement.addEdit.steps.essential.title.minRentalFee'
          ),"step":0.01,"haveSuggestion":_vm.form.tax_enabled,"suggestionText":_vm.getVatIncludedAmount(_vm.form.minimum_rental_fee),"haveUnitText":true,"unitText":_vm.selectedFleetCurrency},model:{value:(_vm.form.minimum_rental_fee),callback:function ($$v) {_vm.$set(_vm.form, "minimum_rental_fee", $$v)},expression:"form.minimum_rental_fee"}})],1),_c('div',{staticClass:"flex items-center mb-3 font-bold text-gray-500 mt-5"},[_c('span',[_vm._v(_vm._s(_vm.$t( 'components.rentPricingPlanManagement.addEdit.steps.essential.title.rentPackages' )))])]),_vm._l((_vm.rentalPackages),function(rentalPackage,rentalPackageIndex){return _c('section',{key:rentalPackageIndex},[_c('div',{staticClass:"flex items-center my-5 text-sm font-bold text-gray-500 "},[_c('span',[_vm._v(_vm._s(_vm.getRentalPackageTitle(rentalPackage.value)))]),_c('div',{staticClass:"col-span-1 ml-2 cursor-pointer focus:text-gray-400",on:{"click":function($event){return _vm.onAddRentalPackage(rentalPackageIndex, rentalPackage.value)}}},[_c('i',{staticClass:"fas fa-plus-circle",staticStyle:{"color":"black"}})])]),_vm._l((rentalPackage.data),function(packageData,packageDataIndex){return _c('div',{key:packageDataIndex},[_c('div',{staticClass:"flex items-center"},[_c('div',{staticClass:"flex items-start  gap-x-1 w-11/12  "},[_c('div',{staticClass:"w-6/12   "},[_c('AppInput',{attrs:{"type":"richselect","name":_vm.$t(
                    'components.rentPricingPlanManagement.addEdit.steps.essential.title.rentalType'
                  ),"label":_vm.$t(
                    'components.rentPricingPlanManagement.addEdit.steps.essential.title.rentalType'
                  ),"infoDescription":_vm.$t(
                    'components.rentPricingPlanManagement.addEdit.steps.essential.infoDescription.rentalType'
                  ),"placeholder":_vm.$t(
                    'components.rentPricingPlanManagement.addEdit.steps.essential.placeHolder.rentalType'
                  ),"value-attribute":"value","text-attribute":"display_name","options":_vm.rentalPackageTypeOptions[rentalPackage.value],"hide-search-box":""},model:{value:(packageData.period),callback:function ($$v) {_vm.$set(packageData, "period", $$v)},expression:"packageData.period"}})],1),_c('div',{staticClass:"w-6/12   "},[_c('AppInput',{attrs:{"type":"text","rules":"required","name":_vm.$t(
                    'components.rentPricingPlanManagement.addEdit.steps.essential.title.rentalCost'
                  ),"label":_vm.$t(
                    'components.rentPricingPlanManagement.addEdit.steps.essential.title.rentalCost'
                  ),"infoDescription":_vm.$t(
                    'components.rentPricingPlanManagement.addEdit.steps.essential.infoDescription.rentalCost'
                  ),"placeholder":"e.g. 5","haveSuggestion":parseFloat(packageData.cost) ? true : false,"isSuggestionAlert":_vm.getSavePercentage(
                    parseFloat(packageData.period),
                    parseFloat(packageData.cost),
                    rentalPackage.value
                  ) < 0
                    ? true
                    : false,"suggestionText":((_vm.form.tax_enabled
                      ? _vm.getVatIncludedAmount(packageData.cost)
                      : '') + ", Save " + (_vm.getSavePercentage(
                    parseFloat(packageData.period),
                    parseFloat(packageData.cost),
                    rentalPackage.value
                  )) + "%"),"haveUnitText":true,"unitText":_vm.selectedFleetCurrency},model:{value:(packageData.cost),callback:function ($$v) {_vm.$set(packageData, "cost", _vm._n($$v))},expression:"packageData.cost"}})],1)]),_c('div',{staticClass:"flex  w-1/12 item-center justify-center"},[_c('div',{staticClass:"col-span-1 cursor-pointer focus:text-gray-400",on:{"click":function($event){return _vm.onRemoveRentPackage(rentalPackageIndex, packageDataIndex)}}},[_c('i',{staticClass:"fas fa-minus-circle",staticStyle:{"color":"#d90a20"}})])])]),_c('div',{staticClass:"pb-2"},[_c('section',[_c('t-checkbox',{attrs:{"wrapped":"","name":_vm.$t(
                  'components.rentPricingPlanManagement.addEdit.steps.essential.title.addNoteCheckbox'
                ),"label":_vm.$t(
                  'components.rentPricingPlanManagement.addEdit.steps.essential.title.addNoteCheckbox'
                )},model:{value:(packageData.addDescriptionUI),callback:function ($$v) {_vm.$set(packageData, "addDescriptionUI", $$v)},expression:"packageData.addDescriptionUI"}})],1),_c('section',{directives:[{name:"show",rawName:"v-show",value:(packageData.addDescriptionUI),expression:"packageData.addDescriptionUI"}]},[_c('AppInput',{attrs:{"rules":"","name":_vm.$t(
                  'components.rentPricingPlanManagement.addEdit.steps.essential.title.notes'
                ),"label":_vm.$t(
                  'components.rentPricingPlanManagement.addEdit.steps.essential.title.notes'
                ),"type":"textarea"},model:{value:(packageData.description),callback:function ($$v) {_vm.$set(packageData, "description", $$v)},expression:"packageData.description"}})],1)])])})],2)}),_c('div',{staticClass:"w-full mt-5"},[_c('AppInput',{attrs:{"type":"textarea","name":_vm.$t(
            'components.rentPricingPlanManagement.addEdit.steps.essential.title.billingDescription'
          ),"label":_vm.$t(
            'components.rentPricingPlanManagement.addEdit.steps.essential.title.billingDescription'
          ),"infoDescription":_vm.$t(
            'components.rentPricingPlanManagement.addEdit.steps.essential.infoDescription.billingDescription'
          )},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}})],1),_c('button',{ref:"submitButton",staticClass:"hidden",attrs:{"type":"submit"}},[_vm._v(" Save ")])],2)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }